<script setup>
import { timeHrsAutoConvert } from '@utils/time'
import AppSelectServer from '@components/AppSelectServer.vue'
import AppInput from '@components/AppInput.vue'
import AppDateTimePicker from '@components/AppDateTimePicker.vue'
import { computed, ref } from 'vue'
import request from '@utils/request'
import { swalError, swalRequestErrorHandler, swalSuccess } from '@utils/swal'
import { useI18n } from 'vue-i18n-bridge'
import _ from 'lodash'
import AppMandayInput from '@components/AppMandayInput.vue'
const props = defineProps({
  readonly: {
    type: Boolean,
    default: false
  },
  selectedEvent: {
    type: Object,
    default: null
  },
  currentAuditPlan: {
    type: String,
    default: null
  },
  currentAudit: {
    type: String,
    default: null
  }
})
const { t } = useI18n()
const loading = ref(false)
const dialogVisible = ref(false)
const editingTimesheetItem = ref(null)
const editingAuditPlan = ref(null)
const editingAudit = ref(null)
const taskTimesheet = ref(null)
const auditPlan = computed({
  get: () => {
    return (
      editingAuditPlan.value || editingTimesheetItem.value?.task?.audit_plan_id
    )
  },
  set: (value) => {
    editingAuditPlan.value = value
    if (editingTimesheetItem.value.task) {
      editingTimesheetItem.value.task.audit_plan_id = value
    }
  }
})
const audit = computed({
  get: () => {
    return editingAudit.value || editingTimesheetItem.value?.task?.audit_id
  },
  set: (value) => {
    editingAudit.value = value
    if (editingTimesheetItem.value.task) {
      editingTimesheetItem.value.task.audit_id = value
    }
  }
})

// for fixed audit plan
const auditPlanObject = ref(null)
const auditObject = ref(null)

const taskListRequestOptions = () => {
  let params = []
  if (auditPlan.value) {
    params.push(`audit_plan_header_id=${auditPlan.value.id}`)
  }
  if (audit.value) {
    params.push(`&audit_header_id=${audit.value.id}`)
  }
  if (!params.length) {
    return {
      url: ''
    }
  }
  return {
    url: `user/task/${params.length > 0 ? '?' + params.join('&') : ''}`
  }
}
const memberTimesheetRequestOptions = () => {
  const auditPlanId = auditPlan.value?.id

  if (!auditPlanId) {
    return {
      url: ''
    }
  }
  return {
    url: `audit/audit-plan-member/?audit_plan_header_id=${auditPlanId}`
  }
}
const mandayTimesheetRequestOptions = () => {
  const taskId = editingTimesheetItem.value.task?.id

  if (!taskId) {
    return {
      url: ''
    }
  }
  return {
    url: `task/task/${taskId}/standard-man-day/?active=true`
  }
}

const emit = defineEmits(['close'])

const getTaskTimesheetDetail = async (taskId, taskTimesheetId) => {
  try {
    const resp = await request.get(
      `audit/task/${taskId}/timesheet/${taskTimesheetId}/`
    )
    return resp.data.detail
  } catch (error) {
    console.error(error)
  }
  return request.get(`audit/task/${taskId}/timesheet/${taskTimesheetId}/`)
}
const closeTimesheetItem = (needRefresh) => {
  dialogVisible.value = false
  taskTimesheet.value = null
  auditPlanObject.value = null
  auditObject.value = null
  emit('close', { needRefresh: needRefresh ?? false })
}
const saveTimesheetItem = async () => {
  loading.value = true
  try {
    const taskId = editingTimesheetItem.value.task?.id
    const taskTimesheetId = editingTimesheetItem.value?.id
    const data = editingTimesheetItem.value

    if (!taskId) {
      throw new Error('Task is required')
    }
    data.task.audit_id = audit.value
    data.task.audit_plan_id = auditPlan.value

    if (data.audit_plan_member) {
      data.audit_plan_member_id = data.audit_plan_member.id
    }

    if (data.assigned_by) {
      data.assigned_by_id = data.assigned_by.id
    }
    data.expected_man_day = timeHrsAutoConvert(data.expected_man_day, true)
    data.actual_man_day = timeHrsAutoConvert(data.actual_man_day, true)
    if (data.task_standard_man_day) {
      data.task_standard_man_day_id = data.task_standard_man_day.id
    }
    try {
      await request({
        method: taskTimesheetId ? 'put' : 'post',
        url: `audit/task/${taskId}/timesheet/${
          taskTimesheetId ? taskTimesheetId + '/' : ''
        }`,
        data: data
      })
      closeTimesheetItem(true)
      swalSuccess(t, 'Successfully saved')
    } catch (response) {
      swalRequestErrorHandler(t, response)
    }
  } finally {
    loading.value = false
  }
}
const deleteTimesheetItem = async () => {
  loading.value = true
  try {
    const taskId = editingTimesheetItem.value.task?.id
    const taskTimesheetId = editingTimesheetItem.value?.id

    if (!taskId) {
      throw new Error('Task is required')
    }
    if (!taskTimesheetId) {
      throw new Error('TaskTimesheetId is required')
    }
    try {
      await request({
        method: 'delete',
        url: `audit/task/${taskId}/timesheet/${taskTimesheetId}`
      })
      closeTimesheetItem(true)
      swalSuccess(t, 'Successfully deleted')
    } catch (response) {
      swalRequestErrorHandler(t, response)
    }
  } finally {
    loading.value = false
  }
}
const onEventClick = async (info) => {
  try {
    loading.value = true
    dialogVisible.value = true

    taskTimesheet.value = await getTaskTimesheetDetail(
      info.event.extendedProps.taskId,
      info.event.extendedProps.taskTimesheetId
    )
    editingTimesheetItem.value = _.cloneDeep(taskTimesheet.value)
  } catch (error) {
    console.error(error)
    swalError(t)
  } finally {
    loading.value = false
  }
}
const onDateClick = async (info) => {
  try {
    loading.value = true
    dialogVisible.value = true
    editingTimesheetItem.value = {
      date: info.date
    }
    if (props.currentAuditPlan) {
      request(`audit/audit-plan-header/${props.currentAuditPlan}/`)
        .then((response) => {
          auditPlanObject.value = response.data.detail
          auditPlan.value = {
            id: response.data.detail.id,
            name: response.data.detail.name
          }
        })
        .catch((error) => {
          console.error(error)
          swalError(t)
        })
    }
    if (props.currentAudit) {
      request(`audit/audit-header/${props.currentAudit}/`)
        .then((response) => {
          auditObject.value = response.data.detail
          audit.value = {
            id: response.data.detail.id,
            name: response.data.detail.name
          }
        })
        .catch((error) => {
          console.error(error)
          swalError(t)
        })
    }
  } catch (error) {
    console.error(error)
    swalError(t)
  } finally {
    loading.value = false
  }
}
defineExpose({
  onEventClick,
  onDateClick
})
</script>

<template>
  <v-dialog v-model="dialogVisible" max-width="600px">
    <v-card :loading="loading">
      <v-card-text>
        <v-container v-if="editingTimesheetItem">
          <v-row>
            <v-col cols="12" sm="6">
              <app-select-server
                ref="timesheet_task_audit_plan"
                name="task_audit_plan"
                :label="$t('fields.auditPlan')"
                :view="props.readonly"
                v-model="auditPlan"
                url="user/audit-plan/"
            /></v-col>
            <v-col cols="12" sm="6">
              <app-select-server
                ref="timesheet_task_audit"
                name="task_audit"
                :label="$t('fields.audit')"
                :view="props.readonly"
                v-model="audit"
                url="user/audit/"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <app-select-server
                ref="timesheet_task"
                name="task"
                :label="$t('fields.task')"
                :view="props.readonly"
                v-model="editingTimesheetItem.task"
                :request-options="taskListRequestOptions"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <app-select-server
                ref="timesheet_assigned_by"
                name="assigned_by"
                :label="$t('fields.assignedBy')"
                :view="props.readonly"
                v-model="editingTimesheetItem.assigned_by"
                :request-options="memberTimesheetRequestOptions"
                item-text="contact_id.name"
            /></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <app-select-server
                ref="timesheet_task_standard_man_day"
                name="task_standard_man_day"
                :label="$t('fields.taskPosition')"
                :view="props.readonly"
                v-model="editingTimesheetItem.task_standard_man_day"
                :request-options="mandayTimesheetRequestOptions"
                item-text="position.name"
            /></v-col>
            <v-col cols="12" sm="6">
              <app-select-server
                ref="timesheet_audit_plan_member"
                name="name"
                :label="$t('fields.name')"
                :view="props.readonly"
                v-model="editingTimesheetItem.audit_plan_member"
                :request-options="memberTimesheetRequestOptions"
                item-text="contact_id.name"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <app-date-time-picker
                name="date"
                :label="$t('fields.assignedDate')"
                v-model="editingTimesheetItem.date"
                :view="props.readonly"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <app-manday-input
                name="expectedManDay"
                :label="$t('fields.assignedManDay')"
                :view="props.readonly"
                v-model="editingTimesheetItem.expected_man_day"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <app-date-time-picker
                name="actual_start_date"
                :label="$t('fields.actualStartDate')"
                v-model="editingTimesheetItem.actual_start_date"
                :view="props.readonly"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <app-manday-input
                name="actualManDay"
                :label="$t('fields.actualManDay')"
                :view="props.readonly"
                v-model="editingTimesheetItem.actual_man_day"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <app-input
                name="note"
                :label="$t('fields.note')"
                :view="props.readonly"
                v-model="editingTimesheetItem.note"
              ></app-input>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="red darken-1"
          text
          @click="deleteTimesheetItem"
          :disabled="loading"
        >
          {{ t('btn.delete') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="closeTimesheetItem"
          :disabled="loading"
        >
          {{ t('btn.cancel') }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="saveTimesheetItem"
          :disabled="loading"
        >
          {{ t('btn.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
