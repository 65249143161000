<script setup>
import { onMounted, ref } from 'vue'
import { DateTime } from 'luxon'
import _ from 'lodash'

import request from '@utils/request'
import AppCalendar from '@components/AppCalendar.vue'
import AppCalendarFilterPanel from '@components/calendar/AppCalendarFilterPanel.vue'
import AppCalendarTimesheetForm from '@components/view/AppCalendarTimesheetForm.vue'

const props = defineProps({
  readonly: {
    type: Boolean,
    default: false
  },
  hideAuditPlanFilter: {
    type: Boolean,
    default: false
  },
  hideAuditFilter: {
    type: Boolean,
    default: false
  },
  fixedAuditPlans: {
    type: Array,
    default: null
  },
  fixedAudits: {
    type: Array,
    default: null
  },
  initialDate: {
    type: String,
    default: null
  },
  currentAuditPlan: {
    type: String,
    default: null
  },
  currentAudit: {
    type: String,
    default: null
  }
})
const loading = ref(false)

const calendar = ref(null)
const eventSourceColors = ref({})

const filterPanels = ref(0)

const getOrCreateEventSourceColor = (id) => {
  if (!eventSourceColors.value[id]) {
    while (eventSourceColors.value[id] === undefined) {
      const hex = `hsla(${Math.random() * 360}, 100%, 50%, 0.5)`
      if (!_.includes(_.values(eventSourceColors.value), hex)) {
        eventSourceColors.value[id] = hex
      }
    }
  }
  return eventSourceColors.value[id]
}

//--- Filters
const teammates = ref([])
const selectedTeammates = ref()
const refreshTeammates = async () => {
  return request.get('/user/teammate/').then((response) => {
    teammates.value = response.data.results
    _.forEach(response.data.results, (item) => {
      getOrCreateEventSourceColor(item.id)
    })

    if (teammates.value.length > 0 && !selectedTeammates.value) {
      setTeammateToAll()
    }
  })
}
const setTeammateToAll = () => {
  selectedTeammates.value = teammates.value.map((teammate) => teammate.id)
}
const audits = ref([])
const selectedAudits = ref()
const refreshAudits = async () => {
  if (props.fixedAudits != null) {
    selectedAudits.value = props.fixedAudits
    return
  }
  return request.get('/user/audit/').then((response) => {
    audits.value = response.data.results
    if (audits.value.length > 0 && !selectedAudits.value) {
      setAuditToAll()
    }
  })
}
const setAuditToAll = () => {
  selectedAudits.value = audits.value.map((audit) => audit.id)
}
const auditPlans = ref([])
const selectedAuditPlans = ref()
const refreshAuditPlans = async () => {
  if (props.fixedAuditPlans != null) {
    selectedAuditPlans.value = props.fixedAuditPlans
    return
  }
  return request.get('/user/audit-plan/').then((response) => {
    auditPlans.value = response.data.results
    if (auditPlans.value.length > 0 && !selectedAuditPlans.value) {
      setAuditPlanToAll()
    }
  })
}
const setAuditPlanToAll = () => {
  selectedAuditPlans.value = auditPlans.value.map((auditPlan) => auditPlan.id)
}
const refreshFilters = async () => {
  return Promise.all([refreshTeammates(), refreshAudits(), refreshAuditPlans()])
}
onMounted(() => {
  refreshFilters().then(() => {
    refreshCalendar()
  })
})
const resetFilters = () => {
  setTeammateToAll()
  setAuditToAll()
  setAuditPlanToAll()
}

//--- Calendar

const _doIfCalendar = (callback) => {
  if (
    calendar.value &&
    calendar.value.calendar &&
    calendar.value.calendar.calendar
  ) {
    callback()
  }
}

const _transformEvent = (event, timeType) => {
  return {
    id: `${event.task_id}_${event.task_timesheet_id}`,
    title: `${event.audit_name ?? event.audit_plan_name} - ${event.task_name}`,
    start: DateTime.fromISO(event.start).toJSDate(),
    // end: DateTime.fromISO(event.start).toJSDate()
    end: event.end ? DateTime.fromISO(event.end).toJSDate() : null,
    auditId: event.audit_id,
    auditPlanId: event.audit_plan_id,
    taskId: event.task_id,
    taskTimesheetId: event.task_timesheet_id,
    timeType: timeType
  }
}

const _transformResponseLine = (item) => {
  if (!item || !item.assignee) {
    return
  }
  const hex = getOrCreateEventSourceColor(item.assignee.id)
  const planned = {
    id: `planned_${item.assignee.id}`,
    className: 'planned',
    borderColor: hex,
    backgroundColor: '#fff',
    textColor: '#333333',
    events: item.planned
      ? item.planned.map((e) => _transformEvent(e, 'planned'))
      : []
  }
  const actual = {
    id: `actual_${item.assignee.id}`,
    className: 'actual',
    borderColor: hex,
    backgroundColor: hex,
    textColor: '#333333',
    events: item.actual
      ? item.actual.map((e) => _transformEvent(e, 'actual'))
      : []
  }
  _doIfCalendar(() => {
    calendar.value.calendar.calendar.addEventSource(planned)
    calendar.value.calendar.calendar.addEventSource(actual)
  })
}

const refreshCalendar = async () => {
  try {
    loading.value = true
    const response = await request.get('/user/calendar/', {
      params: {
        teammates: selectedTeammates.value,
        audits: selectedAudits.value,
        audit_plans: selectedAuditPlans.value
      }
    })
    _doIfCalendar(() => {
      calendar.value.calendar.calendar.updateSize()
    })
    _doIfCalendar(() => {
      calendar.value.calendar.calendar.getEventSources().forEach((source) => {
        source.remove()
      })
    })
    _.forEach(response.data, _transformResponseLine)
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
}

//--- Dialog
const timesheetForm = ref(null)
const selectedEvent = ref(null)

const onEventClick = async (info) => {
  selectedEvent.value = info.event
  await timesheetForm.value.onEventClick(info)
}
const onDateClick = async (info) => {
  selectedEvent.value = null
  await timesheetForm.value.onDateClick(info)
}
const closeTimesheetItem = ({ needRefresh }) => {
  selectedEvent.value = null
  if (needRefresh) {
    refreshCalendar()
  }
}
const assigneeIndicatorStyle = (item) => {
  if (eventSourceColors.value[item.id]) {
    return {
      color: eventSourceColors.value[item.id]
    }
  }
  return {
    display: 'none'
  }
}
</script>

<template>
  <div>
    <v-row>
      <v-col cols="2">
        <v-app-bar flat color="white">
          <v-toolbar-title class="text-h6 pl-0"> Filters</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="refreshFilters">
                <v-icon>mdi-refresh </v-icon>
              </v-btn>
            </template>
            <span>Refresh filters</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="resetFilters">
                <v-icon>mdi-filter-off </v-icon>
              </v-btn>
            </template>
            <span>Reset filters</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="refreshCalendar">
                <v-icon>mdi-filter </v-icon>
              </v-btn>
            </template>
            <span>Apply filter and refresh calendar</span>
          </v-tooltip>
        </v-app-bar>
        <v-expansion-panels v-model="filterPanels">
          <app-calendar-filter-panel
            panel-key="teammates"
            title="Members"
            v-model="selectedTeammates"
            :list="teammates"
          >
            <template v-slot:append="{ item }">
              <v-icon
                class="assignee-color assignee-color-planned"
                :style="assigneeIndicatorStyle(item)"
              >
                mdi-square-outline
              </v-icon>
              <v-icon
                class="assignee-color assignee-color-actual"
                :style="assigneeIndicatorStyle(item)"
              >
                mdi-square
              </v-icon>
            </template>
          </app-calendar-filter-panel>
          <app-calendar-filter-panel
            v-if="!hideAuditFilter"
            panel-key="audits"
            title="Audits"
            v-model="selectedAudits"
            :list="audits"
          />
          <app-calendar-filter-panel
            v-if="!hideAuditPlanFilter"
            panel-key="audit-plans"
            title="Audit Plans"
            v-model="selectedAuditPlans"
            :list="auditPlans"
          />
        </v-expansion-panels>
      </v-col>
      <v-col cols="10">
        <app-calendar
          ref="calendar"
          @event-click="onEventClick"
          @date-click="onDateClick"
          :initialDate="initialDate"
        />
      </v-col>
    </v-row>
    <app-calendar-timesheet-form
      ref="timesheetForm"
      :readonly="props.readonly"
      :selected-event="selectedEvent"
      @close="closeTimesheetItem"
      :current-audit="props.currentAudit"
      :current-audit-plan="props.currentAuditPlan"
    />
  </div>
</template>
